import { createApp } from 'vue';
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import App from './App.vue';
import '../../globalStyle/index.scss';
import '../../utils/rem';
import store from '../../store';
import { createRouter, createWebHistory } from 'vue-router';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import routes from '../../router/index';
const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.config.globalProperties.$loading = (payload) => {
  store.dispatch('setLoading', payload);
};
app.config.globalProperties.$openToast = (payload) => {
  store.dispatch('openToast', payload);
};

app.use(router);
app.use(store);
app.use(VueVirtualScroller);

// app.use(ElementPlus)
app.mount('#app');

console.log('env', process.env);
