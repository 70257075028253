// 基准大小
const baseSize = 144;
document.onDOMContentLoaded = null;
window.onpageshow = null;
window.onresize = null;

// 设置 rem 函数
export function setRem() {
  // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 1440;
  // 设置页面根节点字体大小,Math.min(scal,2)表示最大缩放比例为2
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 1.5) + 'px';
  console.log(baseSize * Math.min(scale, 1.5), '1440');
}

var docEl = document.documentElement; // 返回文档的root元素
var dpr = window.devicePixelRatio || 1; // 获取设备的dpr，即当前设置下物理像素与虚拟像素的比值

// adjust body font size 设置默认字体大小，默认的字体大小继承自body
function setBodyFontSize() {
  if (document.body) {
    document.body.style.fontSize = 12 * dpr + 'px';
  } else {
    document.onDOMContentLoaded = setBodyFontSize;
  }
}
setBodyFontSize();

// function setRemUnit() {
//   const maxSize = 144;
//   var rem = docEl.clientWidth / 10;
//   rem = Math.min(rem, maxSize);
//   docEl.style.fontSize = rem + 'px';
// }

// reset rem unit on page resize
window.onresize = setRem;
window.onpageshow = function (e) {
  if (e.persisted) {
    setRem();
  }
};
// detect 0.5px supports  检测是否支持0.5像素，解决1px在高清屏多像素问题，需要css的配合。
if (dpr >= 2) {
  var fakeBody = document.createElement('body');
  var testElement = document.createElement('div');
  testElement.style.border = '.5px solid transparent';
  fakeBody.appendChild(testElement);
  docEl.appendChild(fakeBody);
  if (testElement.offsetHeight === 1) {
    docEl.classList.add('hairlines');
  }
  docEl.removeChild(fakeBody);
}
window.onresize = function () {
  setRem();
};

setRem();

export const rem = (pxVal) => {
  return pxVal / parseInt(document.body.style.fontSize) + 'rem';
};

export const getPX = (pxVal) => {
  return (parseInt(window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize) * pxVal) / baseSize;
};
