export default [
  {
    name: 'home',
    path: '/',
    component: () => import('@/views/homepage'),
  },
  {
    name: 'feed',
    path: '/:name',
    meta: {
      needParticle: true,
    },
    component: () => import('@/views/feed'),
  },
  {
    name: 'detail',
    path: '/detail/:id',
    meta: {
      needParticle: true,
    },
    component: () => import('@/views/feed/detail'),
  },
  {
    name: 'feedDetail',
    path: '/:name/:id',
    meta: {
      needParticle: true,
    },
    component: () => import('@/views/feed/detail'),
  },
];
