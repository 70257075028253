import { getPoint, getReceiveData, receiveAward } from '../../apis/personalCenter';

const userStore = {
  namespaced: true,
  state: {
    balance: 0,
    mainAccount: {
      address: '',
      img: '',
      name: '',
      allAddresses: [],
    },
    points: 0,
    receiveData: null,
  },
  getters: {},
  mutations: {
    setBalance(state, payload) {
      state.balance = payload;
    },
    SET_MAIN_ACCOUNT(state, account) {
      state.mainAccount = {
        address: account.address,
        img: account.img,
        name: account.name,
        allAddresses: account.allAddresses || [],
      };
    },
    SET_POINTS(state, points) {
      state.points = points;
    },
    SET_RECEIVE_DATA(state, data) {
      state.receiveData = data;
    },
  },
  actions: {
    async getBalance({ state, commit }) {
      const balance = await state.web3.eth.getBalance(state.account);
      commit('setBalance', balance);
    },
    setMainAccount({ commit }, account) {
      commit('SET_MAIN_ACCOUNT', account);
    },
    getPoints({ commit }) {
      getPoint().then((res) => {
        commit('SET_POINTS', res.data || 0);
      });
    },
    getReceiveData({ commit }) {
      getReceiveData().then((res) => {
        commit('SET_RECEIVE_DATA', res.data);
      });
    },
    receiveAward({ dispatch }, payload) {
      receiveAward(payload).then((res) => {
        if (res.code === 0) {
          dispatch('getReceiveData');
        }
      });
    },
  },
};
export default userStore;
