import { getLocalToken, setLocalToken, getParticleUserInfo, cleanParticleToken } from '../utils/tool';
import { walletLogin } from '../apis/personalCenter';

function getEmail(userInfo) {
  for (let key in userInfo) {
    if (key.indexOf('_email') > -1) {
      if (userInfo[key]) {
        return userInfo[key];
      }
    }
  }
}

export function fetchToken(userInfo) {
  const email = getEmail(userInfo);
  return walletLogin({
    email: email,
    particle_uuid: userInfo.uuid,
    particle_token: userInfo.token,
    from: 'web',
  }).then((res) => {
    return res;
  });
}
export function fetchMetaMaskToken(address, signature) {
  return walletLogin({
    wallet_type: 5,
    wallet_address: address,
    signature,
  }).then((res) => {
    return res;
  });
}

// export default async function getToken() {
//   let token = getLocalToken();
//   const isLogin = window.particle.auth.isLogin();
//   if (!token || !isLogin) {
//     const userInfo = await getParticleUserInfo();
//     const res = await fetchToken(userInfo);
//     if (res.code === 20108) {
//       localStorage.clear();
//       const res = await getParticleUserInfo(true);
//       if (res) {
//         token = await getToken();
//       } else {
//         window.location.reload();
//       }
//     }
//     token = res?.data?.token || undefined;
//     token && setLocalToken(token);
//   }
//   return token;
// }
export default async function getToken() {
  let token = getLocalToken();
  const isLogin = window.particle.auth.isLogin();
  if (!token && isLogin) {
    const userInfo = await getParticleUserInfo();
    const res = await fetchToken(userInfo);
    // if (res.code === 20108) {
    //   localStorage.clear();
    //   const res = await getParticleUserInfo(true);
    //   if (res) {
    //     token = await getToken();
    //   } else {
    //     window.location.reload();
    //   }
    // }
    token = res?.data?.token || undefined;
    token && setLocalToken(token);
  }
  return token;
}
export async function getParticleLoginToken() {
  let token = getLocalToken();
  const isLogin = window.particle.auth.isLogin();
  if (!token || !isLogin) {
    const userInfo = await getParticleUserInfo();
    const res = await fetchToken(userInfo);
    if (res.code === 20108) {
      localStorage.clear();
      const res = await getParticleUserInfo(true);
      if (res) {
        token = await getParticleLoginToken();
      } else {
        window.location.reload();
      }
    }
    token = res?.data?.token || undefined;
    token && setLocalToken(token);
  }
  return token;
}

export async function tokenExpired(callback = () => {}) {
  // localStorage.clear();
  cleanParticleToken();
  let token = '';
  if (window.particle.auth.isLogin()) {
    token = await getParticleLoginToken();
    await callback();
  } else if (localStorage.getItem('wallet_type')) {
    await callback();
  }
  return token;
}
