<template>
  <div class="loading" v-if="showLoading">
    <div class="loading_animation"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'LoadingItem',
  computed: {
    ...mapState(['showLoading']),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}
.loading_animation {
  width: 190px;
  height: 190px;
  background-image: url('../../assets/loading.png');
  background-size: auto 100%;
  animation: turn 2s steps(36) infinite;
}
@keyframes turn {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
</style>
