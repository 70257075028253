// import Vue from 'vue'
import Vuex from 'vuex';
// import web3ModalStore from '@/store/modules/web3Modal';
// import predictionStore from '@/store/modules/prediction';
import userStore from '@/store/modules/user';
// import genesisBoxes from '@/store/modules/genesisBoxes';

// Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLoading: false,
    showToast: false,
    toastTxt: '',
    toastTimer: undefined,
  },
  getters: {},
  mutations: {
    SHOW_LOADING(state, payload) {
      state.showLoading = payload;
    },
    SHOW_TOAST(state, payload) {
      state.showToast = payload;
    },
    SET_TOAST_TXT(state, payload) {
      state.toastTxt = payload;
    },
    SET_TOAST_TIMER(state, payload) {
      state.toastTimer = payload;
    },
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('SHOW_LOADING', payload);
    },
    openToast({ commit, state }, payload) {
      clearTimeout(state.toastTimer);
      commit('SHOW_TOAST', true);
      commit('SET_TOAST_TXT', payload);
      let timer = setTimeout(() => {
        commit('SHOW_TOAST', false);
      }, 1000);
      commit('SET_TOAST_TIMER', timer);
    },
  },
  modules: {
    // web3Modal: web3ModalStore,
    // prediction: predictionStore,
    user: userStore,
    // genesisBoxes,
  },
});
