//utc转13位时间戳
export function utc2Timestamp(str) {
  let T_pos = str.indexOf('T');
  let Z_pos = str.indexOf('Z');
  let year_month_day = str.substr(0, T_pos);
  let hour_minute_second = str.substr(T_pos + 1, Z_pos - T_pos - 1);
  let new_datetime = year_month_day + ' ' + hour_minute_second; // 2017-03-31 08:02:06

  // 处理成为时间戳
  let timestamp = new Date(Date.parse(new_datetime));
  return timestamp.getTime();
}
//10位时间戳转本地时间
export function timestamp2LocalDate(t) {
  const date = new Date(t * 1000);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function shortAddress(addr) {
  if (addr && addr.length > 10) {
    return addr.substring(0, 6) + '...' + addr.substring(addr.length - 4, addr.length);
  } else {
    return '';
  }
}
export async function getParticleUserInfo(override = false) {
  const userInfo = window.particle.auth.userInfo();
  if (userInfo && !override) {
    return userInfo;
  }
  cleanParticleToken();
  return window.particle.auth
    .login({
      // preferredAuthType: type,
      // account: input_content,
      supportAuthTypes: 'email,facebook,google,apple,phone',
      // socialLoginPrompt: 'consent',
      // loginFormMode: false,
      // hideLoading: type === 'jwt',
    })
    .then((userInfo) => {
      return Promise.resolve(userInfo);
    })
    .catch((error) => {
      if (error.code !== 4011) {
        alert('error');
      }
    });
}

export function setLocalToken(token) {
  return localStorage.setItem('particle_token', token);
}

export function getLocalToken() {
  return localStorage.getItem('particle_token');
}
export function cleanParticleToken() {
  return localStorage.setItem('particle_token', '');
}

export function formatDate(date, link = '.') {
  date = new Date(date * 1000);
  var myyear = date.getFullYear();
  var mymonth = date.getMonth() + 1;
  var myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = '0' + mymonth;
  }
  if (myweekday < 10) {
    myweekday = '0' + myweekday;
  }
  return myyear + link + mymonth + link + myweekday;
}
export function changeURLStatic(name, value) {
  let url = changeURLParam(location.href, name, value); // 修改 URL 参数
  history.replaceState(null, null, url); // 替换地址栏
}

export function changeURLParam(url, name, value) {
  if (typeof value === 'string') {
    value = value.toString().replace(/(^\s*)|(\s*$)/, ''); // 移除首尾空格
  }
  let url2;
  if (!value) {
    // remove
    let reg = eval('/(([?|&])' + name + '=[^&]*)(&)?/i');
    let res = url.match(reg);
    if (res) {
      if (res[2] && res[2] === '?') {
        // before has ?
        if (res[3]) {
          // after has &
          url2 = url.replace(reg, '?');
        } else {
          url2 = url.replace(reg, '');
        }
      } else {
        url2 = url.replace(reg, '$3');
      }
    }
  } else {
    let reg = eval('/([?|&]' + name + '=)[^&]*/i');
    if (url.match(reg)) {
      // edit
      url2 = url.replace(reg, '$1' + value);
    } else {
      // add
      let reg = /([?](\w+=?)?)[^&]*/i;
      let res = url.match(reg);
      url2 = url;
      if (res) {
        if (res[0] !== '?') {
          url2 += '&';
        }
      } else {
        url2 += '?';
      }
      url2 += name + '=' + value;
    }
  }
  return url2;
}
export const rem = (pxVal) => {
  return pxVal / parseInt(document.body.style.fontSize) + 'rem';
};

export const getPX = (pxVal) => {
  return (parseInt(window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize) * pxVal) / baseSize;
};
