import axios from 'axios';
import getToken from './getToken';
import qs from 'qs';
const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
import { getLocalToken } from "../utils/tool";

const hostMap = {
  develop: 'https://app.readonup.com',
  // develop: 'https://readon-api-staging.readon.me',
  // develop: 'https://readon-api.readon.me',
  staging: 'https://readon-api-staging.readon.me',
  prod: 'https://readon-api.readon.me',
};

let getTokenFnDef = () => {
  console.log('getTokenFnDef');
}
export const setGetTokenFnDef = fn => {
  getTokenFnDef = fn;
}

export const request = axios.create({
  baseURL: hostMap[queryObj.env] || hostMap[process.env.VUE_APP_ENV] || hostMap.prod,
});
const handle = async (res, requestObj, needToken, getTokenFn = getTokenFnDef) => {
  if (res.status === 200) {
    if (res.data.code === 20007) {
      localStorage.clear();
      if (needToken) {
        let token = getLocalToken();
        if (!token) {
          console.log(getTokenFn,'getTokenFn')
          token = await getTokenFn() || getLocalToken();
        }
        requestObj.headers = {
          authorization: token,
        };
      }
      const res2 = await request(requestObj);
      console.log(res2,'res2')
      return Promise.resolve(res2.data);
    }
    return Promise.resolve(res.data);
  } else {
    return Promise.reject(res);
  }
};
export const get = async (url, params,needToken, getTokenFn = getTokenFnDef, config) => {
  let headers = {};
  if (getTokenFn) {
    let token = getLocalToken();
    if (!token) {
      token = await getTokenFn();
    }
    headers = {
      authorization: token,
    };
  }
  const requestObj = {
    method: "get",
    headers,
    url,
    params,
    ...config,
  };
  return request(requestObj).then(res => {
    return handle(res, requestObj,needToken,getTokenFn);
   }).catch(res => {
    return handle(res, requestObj,needToken,getTokenFn);
   });
};
export const post = async (url, data, needToken, getTokenFn = getTokenFnDef, config) => {
  let headers = {};
  if (needToken) {
    let token = getLocalToken();
    if (!token) {
      token = await getTokenFn();
    }
    headers = {
      authorization: token,
    };
  }
  const requestObj = {
    method: "post",
    headers,
    url,
    data,
    ...config,
  };
  return request(requestObj).then(res => {
    return handle(res, requestObj,needToken,getTokenFn);
  }).catch(res => {
     console.log(res,'catch error')
    return handle(res, requestObj,needToken,getTokenFn);
   });
};
