<template>
  <div class="toast" v-if="showToast">
    <div class="toast_txt">{{ toastTxt }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'LoadingItem',
  computed: {
    ...mapState(['showToast', 'toastTxt']),
  },
  created() {},
  data() {
    return {
      end: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  position: fixed;
  padding: 12px 24px;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
  transform: translate(-50%, -50%);
  z-index: 99;
  opacity: 1;
  transition: 0.5s;
  &.end {
    opacity: 0;
  }
}
</style>
